<template>
  <div class="index-environment" >
    <!-- 标题 -->
    <div class="index-environment-title">
      <div class="title_ch">环境氛围</div>
      <div class="title_en">EMPLOYMENT SERVICE</div>
    </div>

    <div class="index-environment-imagelist">
      <div class="index-environment-imagelist-img" 
        v-for="(img,idx) in envList" :key="img.id"
        @mouseover="selectImg(img,idx)" 
        :style="getImg(idx).style">
        <img :src="`${$apiBaseUrl}${img.imgPath}`"/>

        <div class="index-environment-imagelist-img-title-bg" :style="getImg(idx).titleStyle" ></div>
        <!-- <div class="index-environment-imagelist-img-title" :style="getImg(idx).titleStyle">{{img.introduce}}</div> -->
        
      </div>
    </div>
     
  </div>
</template>
<script>

const imageList = [
  {
    style:{width:'57.14%',backgroundColor:'#FFDDDD'},
    titleStyle:{height:'0',color:'white'},
  },
  {
    style:{width:'14.29%',backgroundColor:'#FFAAAA'},
    titleStyle:{height:'100%',color:'#AACFAA'},
  },
  {
    style:{width:'14.29%',backgroundColor:'#FFBBBB'},
    titleStyle:{height:'100%',color:'#AACFAA'},
  },
  {
    style:{width:'14.29%',backgroundColor:'#FFEEEE'},
    titleStyle:{height:'100%',color:'#AACFAA'},
  }
];
const envList = [];
const getEnvironmentList = async function(){
    const res = await this.$httpGet('/front/job/env-list');
   // console.log(res.data)
    this.envList = res.data
};
const getImg = function(idx){
  return imageList[idx]
}
const selectImg = function(img,idx){
 // console.log(idx);
  for(let i=0;i<this.imageList.length;i++){
    if(i==idx)continue;
    this.imageList[i].style.width='14.29%';
    this.imageList[i].titleStyle.height='100%';
    this.imageList[i].titleStyle.color='#AACFAA';
  }
  this.imageList[idx].style.width='57.14%';
  this.imageList[idx].titleStyle.height='0';
  this.imageList[idx].titleStyle.color='white';
};

export default {
  data() {
    return {
       imageList,envList
    };
  },
  created() {
      this.getEnvironmentList()
  },
  mounted() {

  },
  methods: {
    selectImg,getEnvironmentList,getImg
  },
};
</script>

<style scoped >
@import url(./IndexEnvironment.css);
</style>
