

<script>
/** 简短显示内容组件，超出最大长度显示省略号 */
export default {
  props: {
    content: {
      type: String,
      default: "",
    },
    maxLength: {
      type: Number,
      required: true,
    },
  },
};
</script>
<template>
  <span>
  {{
    content.length > maxLength
      ? content.substring(0, maxLength - 2) + "..."
      : content
  }}
  </span>
</template>
