<template>
  <div class="index-job" >

    <div class="index-job-container">

      <!-- <div class="index-job-container-left" ></div> -->

      <div class="index-job-tb">
        <!-- <div v-for="n in 30" :key="n" ></div> -->
      </div>
      <vue-seamless-scroll
        :data="jobList"
        :class-option="option"
        class="index-job-seamless"
      >
        <div class="index-job-seamless-imgs">
          <div
            class="index-job-seamless-imgs-item"
            v-for="(item) in jobList"          
            :key="item.id"
          >
            <img :src="`${$apiBaseUrl}${item.imgPath}`"  />
          </div>
        </div>
      </vue-seamless-scroll>
      <div class="index-job-tb">
        <!-- <div v-for="n in 30" :key="n" ></div> -->
      </div>

      <!-- <div class="index-job-container-right"></div> -->

    </div>



  </div>
</template>
<script>
import VueSeamlessScroll from "vue-seamless-scroll";

const option = {
  step: 1, // 数值越大速度滚动越快
  limitMoveNum: 1, //newsList.length / 2 - 1, // 开始无缝滚动的数据量 this.dataList.length
  hoverStop: true, // 是否开启鼠标悬停stop
  direction: 2, // 0向下 1向上 2向左 3向右
  openWatch: true, // 开启数据实时监控刷新dom
  singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
  singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
  waitTime:0, // 单步运动停止的时间(默认值1000ms)
};

// const getJobList = function(){
//   const arr = [];
//   for(let i=1;i<=19;i++){
//     arr.push({
//       id:i,
//       img:`${i}.jpg`
//     });
//   }
//   this.jobList = arr;
// };

const getJobList = async function(){
  const res = await this.$httpGet("/front/job/detail-list")
  this.jobList = res.data
  console.log(this.jobList)
}


export default {
  components:{
    VueSeamlessScroll
  },
  data() {
    return {
        jobList:[],
        option
    };
  },
  created() {
     this.getJobList();
  },
  mounted() {
    
  },
  methods: {
    getJobList
  },
};
</script>

<style scoped >
@import url(./IndexJob.css);
</style>

