<template>
  <div class="index">
    <!-- 1. 轮播图 start -->
    <index-swiper></index-swiper>
    <!--1.  轮播图 end -->

    <!-- 2. 优势介绍 - start  -->
    <index-superiority></index-superiority>
    <!-- 2. 优势介绍 - end  -->

    <!-- 3. 课程介绍 start -->
    <index-course></index-course>
    <!-- 3. 课程介绍 end -->

    <!-- 4. 师资介绍 - start  -->
    <index-teacher></index-teacher>
    <!-- 4.师资介绍 - end  -->

    <div  class="title-jyfw">
      <div  class="index-title">
        <span class="title_cn_left">就业服务</span><span class="title_cn_right">贯穿全程</span>
        <div class="title_en">EMPLOYMENT INFORMATION SERVICE</div>
      </div>
    </div>
    <div style="height: 32px"></div>
    <x-gcqc></x-gcqc>
    <div style="height: 69px"></div>
    <!-- 5. 环境氛围（在就业服务文档中有介绍）start -->
    <index-environment></index-environment>
    <!-- 5. 环境氛围（在就业服务文档中有介绍）end -->

    <!-- 6. 就业服务（在就业服务文档中有介绍）start -->
    <index-job></index-job>
    <!-- 6. 就业服务（在就业服务文档中有介绍）end -->

    <!-- 7. 就业信息  start-->
    <index-work></index-work>
    <!-- 7. 就业信息  end-->

    <!-- 8. 资讯动态 start -->
    <index-news></index-news>
    <!-- 8. 资讯动态 end -->

    <!-- 9. 企业荣誉 start -->
    <index-honor></index-honor>
    <!-- 9. 企业荣誉 end -->

  </div>
</template>

<script>
import IndexSwiper from './components/IndexSwiper.vue'
import IndexSuperiority from './components/IndexSuperiority.vue'
import IndexTeacher from './components/IndexTeacher.vue'
import IndexCourse from './components/IndexCourse.vue'
import IndexEnvironment from './components/IndexEnvironment.vue'
import IndexJob from './components/IndexJob.vue'
import IndexWork from './components/IndexWork.vue'
import IndexNews from './components/IndexNews.vue'
import IndexHonor from './components/IndexHonor.vue'
import XGcqc from "../../components/XGcqc";



export default {
  components: {
   IndexSwiper,// 轮播图
   IndexSuperiority,// 优势
   IndexTeacher,// 师资力量
   IndexCourse,// 课程介绍
   IndexEnvironment,// 环境氛围
   IndexJob,// 就业服务
   IndexWork,// 就业信息
   IndexNews,// 咨询动态
   IndexHonor,// 企业荣誉
    XGcqc,
  },
  data() {
    return { 
    };
  },
  created() {
  },
  mounted() {},
  methods: {
  },
};
</script>

<style scoped >
@import url(./index.css);
</style>

