<template>
  <div class="index-news">
    <!-- 标题 -->
    <div class="index-news-header">
      <div class="header_ch">资讯动态</div>
      <div class="header_en">INFORMATION TRENDS</div>
    </div>
    <div class="index-news-container">
      <div class="index-news-items" v-for="item in newsList" :key="item.id">
        <div class="index-news-title">
          <div class="index-news-title-left"></div>
          <div class="index-news-title-text">{{item.title}}</div>
        </div>
        <div class="index-news-img">
            <img :src="`${$apiBaseUrl}${item.imgPath}`" />
        </div>
        <div class="index-news-list">
          <ul>
            <li>{{item.news1}}</li>
            <li>{{item.news2}}</li>
            <li>{{item.news3}}</li>
            <li>{{item.news4}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

const newsList = [] ;//资讯列表
const getNewsList = async function() {
    const res = await this.$httpGet('front/news/list');
    this.newsList = res.data;
    console.log(this.newsList)
};

export default {
  data() {
    return {
      newsList
    };
  },
  created() {
    this.getNewsList()
  },
  mounted() {},
  methods: {
      getNewsList
  }
};
</script>

<style scoped >
@import url(./IndexNews.css);
</style>

