<template>
  <div class="index-course">
    <!-- 标题 -->
    <div class="index-course-title">
      <div class="title_ch">课程介绍</div>
      <div class="title_en">COURSE INTRODUCTION</div>
    </div>
    
    <div class="index-course-list">
      <div class="index-course-list-item" v-for="(course,idx) in courseList" :key="idx">
        <div class="index-course-list-item-left"
          :style="{backgroundImage: `url(${$baseUrl}${course.leftBg})`}"
        >
          <div class="index-course-list-item-name">
              <img :src="`${$baseUrl}${course.icon}`"/>
              <div class="text" v-text="course.name"></div>
          </div>
          <div class="index-course-list-item-servicelist">
              <div v-for="serviceItem in course.serviceList" :key="serviceItem.id" v-text="serviceItem.service"></div>
          </div>
        </div>
        
        <div class="index-course-list-item-contentlist"
          :style="{backgroundImage: `url(${$baseUrl}${course.rightBg})`}"
        >
          <div  v-for="contentItem in course.contentList" :key="contentItem.id">
            <img :src="`${$baseUrl}${contentItem.icon}`"/>
            <div class="text" v-html="contentItem.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const courseList = [
  {
    id: 1,
    name: "Java",
    icon:'/index/course/icon_java.png',
    leftBg: '/index/course/bg_java_left.png',
    rightBg: '/index/course/bg_java_right.png',
    serviceList: [
      { id: 1101, service: "免费试学，先体验后报名" },
      { id: 1102, service: "就业推荐，签订就业协议" },
      { id: 1103, service: "试学不满意支持全额退款" },
    ],
    contentList: [
      { id: 1201, icon:'/index/course/icon_java_1.png', content: "零基础入门" },
      { id: 1202, icon:'/index/course/icon_java_2.png', content: "互联网大厂<br\>前沿技术框架" },
      { id: 1203, icon:'/index/course/icon_java_3.png', content: "企业级项目实战<br\>涵盖多行业多领域" },
      { id: 1204, icon:'/index/course/icon_java_4.png', content: "多阶段测评<br\>保障学习效率" },
      { id: 1205, icon:'/index/course/icon_java_5.png', content: "互联网名企<br\>内推、双选会" },
      { id: 1206, icon:'/index/course/icon_java_6.png', content: "沟通演讲团队协作<br\>提升综合能力" },
    ],
  },
  {
    id: 2,
    name: "C++",
    icon:'/index/course/icon_C++.png',
    leftBg: '/index/course/bg_c++_left.png',
    rightBg: '/index/course/bg_c++_right.png',
    serviceList: [
      { id: 2101, service: "免费试学，先体验后报名" },
      { id: 2102, service: "就业推荐，签订就业协议" },
      { id: 2103, service: "试学不满意支持全额退款" },
    ],
    contentList: [
      { id: 2201, icon:'/index/course/icon_c++_1.png', content: "零基础入门" },
      { id: 2202, icon:'/index/course/icon_c++_2.png', content: "技术点持续更新<br\>紧跟市场需求" },
      { id: 2203, icon:'/index/course/icon_c++_3.png', content: "企业级项目实战<br\>涵盖多行业多领域" },
      { id: 2204, icon:'/index/course/icon_c++_4.png', content: "多阶段测评<br\>保障学习效率" },
      { id: 2205, icon:'/index/course/icon_c++_5.png', content: "互联网名企<br\>内推、双选会" },
      { id: 2206, icon:'/index/course/icon_c++_6.png', content: "大厂经验分享<br\>培养架构能力" },
    ],
  },
];

export default {
  data() {
    return {
      courseList,
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped >
@import url(./IndexCourse.css);
</style>
