<template>
  <div class="superiority-container">
    <!-- 十大大优势 - start  -->
    <div class="index-superiority">
      <div class="index-superiority-title">
        <span class="title_cn_left">文都智链</span><span class="title_cn_right">十大课程优势</span>
        <div class="title_en">TEN COURSE ADVANTAGES</div>
      </div>

      <div class="index-superiority-list">
        <div
          class="index-superiority-list-item"
          v-for="(superiority,idx) in superiorityList"
          @mouseenter="mouseEnterEvent(idx)"
          @mouseleave="mouseLeaveEvent"
          :style="background(idx)"
          :key="superiority.id"
        >
          <img
            v-if="iconVisible(idx)"
            :src="require(`@/assets/index/position/${superiority.icon}`)"
            class="index-superiority-list-item-img"
          />
          <img
            v-if="iconHoverVisible(idx)"
            :src="require(`@/assets/index/position/${superiority.iconHover}`)"
            class="index-superiority-list-item-img"
          />
          <div
            v-text="superiority.title"
            class="index-superiority-list-item-title"
            :style="itemTitleStyle(idx)"
          ></div>
          <div
            v-text="superiority.shortContent"
            class="index-superiority-list-item-content"
            :style="itemContentStyle(idx)"
          ></div>
        </div>

      </div>
    </div>
    <!-- 十大大优势 - end  -->
  </div>
</template>

<script>
const superiorityList = []; // 优势列表
const getSuperiorityList = async function () {
  const result = await this.$httpGet("/front/position/list");
  this.superiorityList = result.data;
};
const curIdx = -1;
const imgHover = 0;
const mouseEnterEvent = function(idx){
  this.curIdx = idx;
}
const mouseLeaveEvent = function(idx){
  this.curIdx = -1;
}
const iconVisible = function(idx){
  return this.curIdx!=idx;
}
const iconHoverVisible = function(idx){
  return this.curIdx==idx;
}
const itemTitleStyle = function(idx){
  if(this.curIdx==idx) return {'color': '#FFF'};
  return {'color': '#000'}
}
const itemContentStyle = function(idx){
  if(this.curIdx==idx) return {'color': '#FFF'};
  return {'color': '#666'}
}
const background = function(idx){
  if(this.curIdx==idx) return {'background-color': '#1C70FF'};
  return {'background-color': '#FFF'}
}
export default {
 
  data() {
    return {
      curIdx,     
      superiorityList
    };
  },
  created() {
    this.getSuperiorityList(); 
  },
  mounted() {},
  methods: {
    getSuperiorityList,
    mouseEnterEvent,
    mouseLeaveEvent,
    iconVisible,
    iconHoverVisible,
    itemTitleStyle,
    itemContentStyle,
    background,
  },
};

</script>

<style scoped >
@import url(./IndexSuperiority.css);
</style>
