<template>

    <!-- 轮播图 start -->
    <div class="index-swiper">
      <swiper ref="mySwiper" :options="swiperOptions" style="height: 500px">
        <swiper-slide v-for="swiper in swiperList" :key="swiper.id"
          ><img
            :src="`${$baseUrl}/index/banner/${swiper.imageUrl}`"
            style="width: 100%; height: 100%"
        /></swiper-slide>

        <!-- <swiper-slide>Slide 4</swiper-slide>
            <swiper-slide>Slide 5</swiper-slide> -->
        <div class="swiper-pagination" slot="pagination"></div>

        <!-- <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div> -->
      </swiper>
    </div>
    <!-- 轮播图 end -->

</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";



const swiperOptions = {
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  // Some Swiper option/callback...
  loop: true,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
};

const swiperList = [
  //{ id: 1, imageUrl: "banner_1.png" },
  { id: 1, imageUrl: "banner_1.jpg" },//20240814付小荣要求修改
  { id: 2, imageUrl: "banner_2.png" },
  // { id: 3, imageUrl: "banner_3.png" },//20220914李睿要求去掉
  { id: 4, imageUrl: "banner_4.png" },
];



export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOptions,
      swiperList,
    };
  },
  created() {
  },
  mounted() {},
  methods: {
  },
};
</script>

<style scoped >

</style>

<style>
@import url(./IndexSwiper.css);
</style>